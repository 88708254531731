//variables
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800|Raleway:400,500,600,700,800,900&display=swap");
$iphoneSE: 325px;
$phone: 400px;
$smallTablet: 520px;
$mediumTablet: 700px;
$tablet: 825px;
$smallScreen: 1040px;
$bigScreen: 1150px;
$hugeScreen: 1270px;
$transition: all 0.2s ease;
//https://i.kym-cdn.com/photos/images/original/000/970/154/2ef.gif//
.App.newBg {
  transition: 1.5s;
  background-image: url("../img/night.gif");
}
.App {
  background-image: url("../img/portfolio-gif-day.gif");
  background-color: #0a272f;
  object-fit: fill;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 1s;

  .flip {
    transform: rotateY(180deg);
    .front {
      opacity: 0;
    }
    .back {
      opacity: 1;
      display: inline-block;
      position: absolute;
      height: 100%;
      width: 100%;
      animation: fadein 2s;
      @keyframes fadein {
        from {
          display: none;
        }
        to {
          display: inline-block;
        }
      }
    }
  }
  .front,
  .back {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-delay: 0.25s;
    transition: 0;
    backface-visibility: hidden;
    background-color: white;
  }
  .front {
    transform: rotateY(0deg);
  }
  .back {
    transform: rotateY(180deg);
    opacity: 0;
    display: none;
    backface-visibility: visible;
    background-color: #3a3a3a;
    color: white;
    .back-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      background: #3a3a3a;
      animation: fadeIn 1s;
      h1 {
        text-align: center;
        font-weight: 400;
        margin: 1rem auto;
      }
      .back-btn-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        .back-btn {
          margin-top: 0.7rem;
          padding: 0.25rem 0.4rem;
          border: 1px solid white;
          border-radius: 5px;
          text-align: center;
          cursor: pointer;
          &:hover {
            background: white;
            color: black;
            transition: 0.5s;
          }
        }
      }
    }
  }

  .user-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat";
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.45);
    overflow: hidden;
    transition: 1s;
    transform-style: preserve-3d;
    @media screen and (max-width: 1000px) {
      width: 60%;
    }

    @media screen and (max-width: $tablet) {
      width: 70%;
    }
    @media screen and (max-width: $mediumTablet) {
      width: 75%;
    }
    @media screen and (max-width: $smallTablet) {
      width: 80%;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      -webkit-animation: fadein 3s;
      animation: fadein 2s;
      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      h2 {
        margin: 0.5rem 0;
        font-weight: 600;
        font-family: "Raleway";
      }
      p {
        font-family: "Raleway";
        margin: 0;
        font-size: 1.1rem;
        font-weight: 600;
      }

      h3 {
        text-indent: 0.6rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 23px;
        text-align: center;
        margin-bottom: 4rem;
      }
      .bg {
        animation: pulse 2s ease infinite;
      }
      .portfolioBtnDiv {
        display: flex;
        justify-content: center;
      }
      .btn,
      .bg {
        position: absolute;
        top: 72%;
        width: 4.5rem;
        height: 1.2rem;
        text-align: center;
        background-color: #ed8e9e;
        padding: 0.5rem 1rem;
        border-radius: 15px;
        color: white;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          background-color: #df4ae4;
          transition: 1.5s;
        }
        @media screen and (max-width: 1000px) {
          top: 70%;
        }
      }
      @keyframes pulse {
        0% {
          transform: scale(1, 1);
        }
        50% {
          opacity: 0.3;
        }
        100% {
          transform: scale(1.2);
          opacity: 0;
        }
      }
    }

    .avatar-con {
      width: 65%;
      display: flex;
      flex-direction: column;
      align-items: center;
      -webkit-animation: fadein 2s;
      animation: fadein 1s;
      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
    .avatar {
      width: 40%;
      margin-top: 2rem;
      border-radius: 50%;
    }
    .svg-footer {
      -webkit-animation: fadein 3s;
      animation: fadein 4s;
      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1.5rem 0;
      width: 100%;
      a {
        width: 50px;
        margin: 0 0.7rem;
        &:hover {
          opacity: 0.6;
        }
        @media screen and (max-width: $smallTablet) {
          width: 40px;
        }
        @media screen and (max-width: $phone) {
          width: 30px;
        }
      }
    }
  }
}
//tabs
.tabrow {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  line-height: 24px;
  height: 26px;
  overflow: hidden;
  font-size: 12px;
  font-family: verdana;
  position: relative;
}
.tabrow li {
  border: 1px solid #aaa;
  background: #6b6b6b;

  display: inline-block;
  position: relative;
  z-index: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin: 0 -5px;
  padding: 0 20px;
}
.tabrow .tab {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.tabrow li.selected {
  background: #3a3a3a;
  color: white;
  z-index: 2;
  border-bottom-color: #3a3a3a;
}
.tabrow:before {
  position: absolute;
  content: " ";
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #aaa;
  z-index: 1;
}
.tabrow li:before,
.tabrow li:after {
  border: 1px solid #aaa;
  position: absolute;
  bottom: -1px;
  width: 5px;
  height: 5px;
  content: " ";
}
.tabrow li:before {
  left: -6px;
  border-bottom-right-radius: 6px;
  border-width: 0 1px 1px 0;
  box-shadow: 2px 2px 0 #6a6a6a;
}
.tabrow li:after {
  right: -6px;
  border-bottom-left-radius: 6px;
  border-width: 0 0 1px 1px;
  box-shadow: -2px 2px 0 #6b6b6b;
}
.tabrow li.selected:before {
  box-shadow: 2px 2px 0 #3a3a3a;
}
.tabrow li.selected:after {
  box-shadow: -2px 2px 0 #3a3a3a;
}

///
.infoCon {
  width: 100%;
  position: absolute;
  bottom: 0%;
  background: black;
  opacity: 0.9;
  .titleBox {
    text-align: center;
    margin-bottom: 0.5rem;
    a {
      background: white;
      color: black;
      text-decoration: none;
      padding: 0.2rem 0.4rem;
      font-weight: bold;
      margin-right: 1rem;
      &:hover {
        background: rgba(201, 201, 201, 0.473);
      }
    }
  }
  p {
    margin: 0.8rem 0.5rem;
  }
}
//carousel
.carousel__dot-group {
  text-align: center;
}
.btnLeft,
.btnRight {
  border: none;
  background-color: rgba(255, 255, 255, 0.418);
  width: 37px;
  border-radius: 50%;
  position: absolute;
  font-size: 1.8rem;
  top: 50%;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.btnRight {
  right: 1%;
}
.btnLeft {
  left: 1%;
}
.legend {
  @media screen and (max-width: $mediumTablet) {
    font-size: 0.8rem;
  }
}
//modal
.ReactModalPortal {
  h2 {
    margin: 0 0 2rem 0;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(255, 255, 255, 0.37);
  }
  .ReactModal__Content {
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        background: none;
        color: white;
        border: none;
        font-size: 1.4rem;
        margin-bottom: 1.8rem;
        cursor: pointer;
      }
    }
    .contact-inputs {
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        label {
          margin-bottom: 0.3rem;
          align-self: flex-start;
          margin-left: 1.5rem;
          font-weight: 700;
        }
        .text-area {
          width: 80%;
          margin-bottom: 2rem;
          border-radius: 2px;
        }
        .send-btn {
          width: 35%;
          height: 1.2rem;
          font-size: 0.8rem;
        }
        input {
          border-radius: 3px;
          margin-bottom: 1rem;
          margin-right: 5rem;
          margin-left: 5rem;
          width: 80%;
          height: 2.5vh;
        }
      }
    }
    font-family: "Montserrat";
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: "-50%";
    transition: "2s";
    background-color: rgb(58, 58, 58);
    color: white;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    animation-name: modalFade;
    animation-duration: 0.6s;

    @keyframes modalFade {
      from {
        transform: translate(-50%, -80%);
        opacity: 0;
      }
      to {
        transform: translateY(-10%, -10%);
        opacity: 1;
      }
    }
  }
}

video {
  width: 100%;
}

//designs section

.designs-container {
  display: flex;
  max-height: 65vh;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
  .thumbnail-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 1rem;
    @media screen and (max-width: 750px) {
      flex-direction: row;
      justify-content: space-around;
      margin-left: 0;
      height: 80%;
    }
    .thumbnail {
      width: 80px;
      height: 80px;
      object-fit: contain;
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
      padding: 3px 0px 3px 3px;
      margin: 5px 1px 3px 0px;
      border: 1px solid rgba(81, 203, 238, 1);
      cursor: pointer;
      @media screen and (max-width: 750px) {
        width: 60px;
        height: 60px;
      }
    }
    .active {
      box-shadow: 0 0 5px #ed8e9e;
      padding: 3px 0px 3px 3px;
      margin: 5px 1px 3px 0px;
      border: 1px solid #ed8e9e;
    }
  }
  .view-container {
    display: flex;
    width: 80%;
    @media screen and (max-width: 750px) {
      width: 100%;
      height: 45vh;
      margin-top: 0.5rem;
    }
    .loader {
      position: absolute;
      top: 30%;
      left: 28%;
      @media screen and (max-width: 1000px) {
        left: 31%;
      }
      @media screen and (max-width: 750px) {
        left: 14%;
        top: 50%;
      }
      @media screen and (max-width: 500px) {
        left: 10%;
      }
      @media screen and (max-width: 400px) {
        left: 5%;
      }
      @media screen and (max-width: 350px) {
        left: 2%;
      }
    }
    .viewBox {
      display: none;
      opacity: 0;
    }
    .application-picture {
      width: 50%;
      align-self: center;
      text-align: center;
      max-height: 100%;

      img {
        width: 88%;
        max-height: 100%;
        object-fit: contain;
        @media screen and (max-width: 750px) {
          width: 80%;
        }
      }
    }

    .fade {
      animation: fadeIn 2s forwards;
      display: flex;
    }
    .design-description {
      display: flex;
      flex-direction: column;
      width: 50%;
      @media screen and (max-width: 750px) {
        padding-right: 2rem;
      }
      .title {
        font-size: 1.2rem;
        @media screen and (max-width: 750px) {
          font-size: 1rem;
          margin-bottom: 0;
        }
      }
      .content {
        font-weight: 300;
        font-size: 1rem;
        @media screen and (max-width: 750px) {
          font-size: 0.8rem;
          line-height: 1.1rem;
        }
        @media screen and (max-width: $smallTablet) {
          font-size: 0.7rem;
        }
        @media screen and (max-width: $phone) {
          font-size: 0.6rem;
          line-height: 0.9rem;
        }
      }
      .figma-btn {
        background: #ed8e9e;
        text-align: center;
        width: 50%;
        padding: 0.5rem 0;
        border-radius: 20px;
        cursor: pointer;
        &:hover {
          background: #e2a1ac;
        }
        a {
          color: white;
          text-decoration: none;
          font-weight: 600;
        }
      }
    }
  }
}

//about me section
.about-container {
  @media screen and (max-width: $mediumTablet) {
    flex-direction: column;
    align-items: center;
  }
  animation: fadeIn 1s;
  margin: 0 1rem;
  display: flex;
  justify-content: space-around;
  .headshot {
    width: 35%;
    margin-top: 10%;
    @media screen and (max-width: $mediumTablet) {
      margin-top: 1rem;
    }
    img {
      border-radius: 50%;
      max-width: 100%;
    }
  }
  .about-content {
    @media screen and (min-width: 1000px) {
      margin-top: 10%;
    }
    @media screen and (max-width: $mediumTablet) {
      width: 100%;
    }

    width: 55%;
    h2 {
      font-weight: 400;
      margin-top: 0;
      @media screen and (max-width: $mediumTablet) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: $smallTablet) {
        font-size: 1rem;
      }
    }
  }
  p {
    @media screen and (max-width: $mediumTablet) {
      font-size: 0.9rem;
    }
    @media screen and (max-width: $smallTablet) {
      font-size: 0.8rem;
    }
    @media screen and (max-width: $iphoneSE) {
      font-size: 0.6rem;
    }
  }
  .intro {
    margin-bottom: 0.5rem;
  }
  .intro,
  .and {
    color: #e2a1ac;
  }
  .about-body {
    line-height: 1.5rem;
  }
}
//about end
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
